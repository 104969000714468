export const PRIORITY_LEVELS = Object.freeze({
  CRITICAL: 1,
  EXTRA_HIGH: 2,
  HIGH: 3,
  MEDIUM: 4,
  LOW: 5,
  EXTRA_LOW: 6,
});

export const PRIORITY_LEVELS_OPTIONS = Object.freeze([
  PRIORITY_LEVELS.CRITICAL,
  PRIORITY_LEVELS.EXTRA_HIGH,
  PRIORITY_LEVELS.HIGH,
  PRIORITY_LEVELS.MEDIUM,
  PRIORITY_LEVELS.LOW,
  PRIORITY_LEVELS.EXTRA_LOW,
]);
