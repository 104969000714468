var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"fileObserver"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Konfiguracja")]),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Język"}},[_c('validation-provider',{attrs:{"name":"Język","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"v-language-select","label":"value","placeholder":"Wybierz język","clearable":false,"deselectFromDropdown":false,"options":_vm.languages},on:{"input":_vm.fetchByLocale},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-3"},[_vm._v("Informacje o pliku")]),_c('b-form-group',{attrs:{"label":"Publiczny"}},[_c('b-form-checkbox',{model:{value:(_vm.form.is_public),callback:function ($$v) {_vm.$set(_vm.form, "is_public", $$v)},expression:"form.is_public"}},[_vm._v(" Publiczny plik będzie widoczny na stronie z materiałami do pobrania ")])],1),_c('b-form-group',{attrs:{"label":"Nazwa"}},[_c('validation-provider',{attrs:{"name":"Nazwa","rules":"required|min:3|max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nazwa","disabled":!_vm.lang},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Kategoria"}},[_c('validation-provider',{attrs:{"name":"Kategoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"v-category","label":"name","placeholder":"Wybierz kategorię","clearable":false,"deselectFromDropdown":false,"options":_vm.tags,"disabled":!_vm.lang},model:{value:(_vm.form.tag),callback:function ($$v) {_vm.$set(_vm.form, "tag", $$v)},expression:"form.tag"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Priorytet"}},[_c('validation-provider',{attrs:{"name":"Priorytet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"v-category","label":"name","placeholder":"Ustaw priorytet","clearable":false,"deselectFromDropdown":false,"options":_vm.prioOptions,"disabled":!_vm.lang},model:{value:(_vm.form.priority),callback:function ($$v) {_vm.$set(_vm.form, "priority", $$v)},expression:"form.priority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Plik"}},[_c('validation-provider',{attrs:{"name":"Plik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"v-image","state":Boolean(_vm.form.file),"browse-text":"Wybierz plik","placeholder":"Wybierz lub przeciągnij plik","drop-placeholder":"Przeciągnij tu plik","accept":".jpg, .png, .gif, .jpeg, .svg, .bmp, .doc, .docx, .zip, .pdf, .","disabled":!_vm.lang},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"attachment-btns-container"},[(_vm.form.url && _vm.form.type)?_c('b-button',{staticClass:"btn",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.downloadFile}},[_vm._v(" Pobierz ")]):_vm._e(),(_vm.form.url)?_c('b-button',{staticClass:"btn",attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.removeFile}},[_vm._v(" Usuń ")]):_vm._e()],1)],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Zapisz ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }