export const convertFromURLToFile = async (url, fileName = 'file') => {
  if (!url) return '';

  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  const mimeType = /[^.]*$/.exec(url)[0];

  return new File([buf], fileName, { type: mimeType });
};

export const base64Encode = (data) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
