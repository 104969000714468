<template>
  <validation-observer ref="fileObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja</h4>

        <b-form-group class="mt-2" label="Język">
          <validation-provider #default="{ errors }" name="Język" rules="required">
            <v-select
              id="v-language-select"
              label="value"
              placeholder="Wybierz język"
              v-model="lang"
              :clearable="false"
              :deselectFromDropdown="false"
              :options="languages"
              @input="fetchByLocale" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mb-2 mt-3">Informacje o pliku</h4>

        <b-form-group label="Publiczny">
          <b-form-checkbox v-model="form.is_public">
            Publiczny plik będzie widoczny na stronie z materiałami do pobrania
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label="Nazwa">
          <validation-provider #default="{ errors }" name="Nazwa" rules="required|min:3|max:250">
            <b-form-input v-model="form.name" placeholder="Nazwa" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Kategoria">
          <validation-provider #default="{ errors }" name="Kategoria" rules="required">
            <v-select
              id="v-category"
              label="name"
              placeholder="Wybierz kategorię"
              v-model="form.tag"
              :clearable="false"
              :deselectFromDropdown="false"
              :options="tags"
              :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Priorytet">
          <validation-provider #default="{ errors }" name="Priorytet" rules="required">
            <v-select
              id="v-category"
              label="name"
              placeholder="Ustaw priorytet"
              v-model="form.priority"
              :clearable="false"
              :deselectFromDropdown="false"
              :options="prioOptions"
              :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Plik">
          <validation-provider #default="{ errors }" name="Plik" rules="required">
            <b-form-file
              id="v-image"
              v-model="form.file"
              :state="Boolean(form.file)"
              browse-text="Wybierz plik"
              placeholder="Wybierz lub przeciągnij plik"
              drop-placeholder="Przeciągnij tu plik"
              accept=".jpg, .png, .gif, .jpeg, .svg, .bmp, .doc, .docx, .zip, .pdf, ."
              :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <div class="attachment-btns-container">
          <b-button v-if="form.url && form.type" @click="downloadFile" variant="primary" class="btn" size="sm">
            Pobierz
          </b-button>
          <b-button v-if="form.url" @click="removeFile" variant="danger" class="btn" size="sm"> Usuń </b-button>
        </div>
      </b-col>

      <b-col class="mt-5">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"> Zapisz </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin.vue';
import { getLanguages } from '@/constants/languages';
import { all as fetchTagsByLocale } from '@/http/downloadable-files-tags';
import { base64Encode } from '@/constants/utils/file';

import { PRIORITY_LEVELS_OPTIONS } from '@/constants/enums/priority';

export default {
  mixins: [NotificationMixin],
  props: {
    file: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      tags: [],
      lang: {
        lang: 'pl',
        value: 'Polski',
      },
      form: this.file,
      languages: getLanguages(),
      prioOptions: PRIORITY_LEVELS_OPTIONS,
    };
  },

  created() {
    this.fetchTags();
  },

  watch: {
    async file(newValue) {
      this.lang = this.languages.find((l) => l.lang === newValue.lang);
      this.form = this.file;

      this.fetchTags();
    },
    tags() {
      if (this.form.tag) {
        this.form.tag = this.tags.find((tag) => tag.id === this.form.tag.id);
      }
    },
    async 'form.file'(newValue) {
      if (newValue) {
        this.form.url = await base64Encode(newValue);
      }
    },
  },

  methods: {
    async fetchTags() {
      try {
        const { data } = await fetchTagsByLocale({ lang: this.lang.lang });

        this.tags = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Błąd przy pobieraniu kategorii',
          'Nie udało się pobrać kategorii. Skontaktuj się ze swoim developerem.',
        );
      }
    },
    fetchByLocale() {
      this.fetchTags();
    },
    removeFile() {
      this.form.url = null;
      this.form.file = null;
    },
    onSubmit() {
      const data = {
        ...this.form,
        lang: this.lang.lang,
      };

      this.$emit('onSubmit', data);
    },
    validationForm() {
      this.$refs.fileObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
    downloadFile() {
      fetch(this.form.url).then((response) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.setAttribute('download', `${this.form.name ?? 'default_file_name'}.${this.form.type}`);
          a.setAttribute('href', url);
          a.click();
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.attachment-btns-container {
  display: flex;
  gap: 15px;
}
</style>
